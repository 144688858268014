window.addEventListener('load', () => {
    registerServiceWorker();
});

function registerServiceWorker() {
    if ('serviceWorker' in navigator) {
        try {
            navigator
                .serviceWorker
                .register(`${process.env.WEBPACK_PUBLIC_PATH}/../serviceworker.js`, {
                    scope: `${process.env.WEBPACK_PUBLIC_PATH}/../`
                });
        }
        catch (e) {
            console.log('SW registration failed. This will not work with invalid SSL or Private mode');
        }
    }   
}

