document.addEventListener("DOMContentLoaded", function() {
    let jsAllChoices = document.querySelectorAll(".js-all-choices");
    let searchGroupedChoices = document.querySelectorAll("#searchGroupedChoices");
    
    if(jsAllChoices.length > 0 && searchGroupedChoices.length > 0){
        //Select all elements from Role
        jsAllChoices.forEach( element => {
            element.onclick = function(){
                let checked = element.checked;
                document.querySelectorAll(".js-role-" + element.dataset.rolGroup).forEach( checkbox => {
                    checkbox.checked = checked;
                });
            };
        });

        //Search roles
        searchGroupedChoices.forEach( searchElement => {
            searchElement.addEventListener('keyup', function(){
                let textToSearch = this.value;
                document.querySelectorAll(".znv-group-role").forEach( function(el) {
                    if(el.getAttribute("name").toLowerCase().indexOf(textToSearch.toLowerCase()) != -1){
                        el.style.display = 'block';
                    }else{
                        el.style.display = 'none';
                    }
                });
            });
        });

        //Clear search
        document.getElementById("clearGroupedChoices").addEventListener("click", function(){
            document.querySelectorAll(".znv-group-role").forEach( function(el) {
                el.style.display = 'block';
            });
        });
    }
});