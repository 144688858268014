class ZnvDropdown {
    static dropdowns = [];
    static actions = [];

    constructor(element, options = {}) {

    }
  
    static add(id, element){
        this.dropdowns[id] = new TomSelect(element, {});
    }

    static get(id){
        if(this.dropdowns[id]){
            return this.dropdowns[id];
        }
        console.error('Id does not exist in dropdowns list', id);
    }

    static subscribe(action, func){
        if(!this.actions[action]){
            this.actions[action] = [];
        }
        this.actions[action].push(func);
    }

    static trigger(action){
        if(this.actions[action]){
            this.actions[action].forEach(function(func){
                func();
            });
        }
    }
}

document.addEventListener("DOMContentLoaded", function() {
    if (document.querySelector(".tom-select-simple")){
        var selectList = document.querySelectorAll(".tom-select-simple");
        selectList.forEach(function(selectElement){
            ZnvDropdown.add(selectElement.id, selectElement);
        });
        ZnvDropdown.trigger("dropdownsLoaded");
    }
});



export default ZnvDropdown;