document.addEventListener("DOMContentLoaded", function() {
    closeSideBar();
    openSideBar();
    storeMenuStatus();
});

function storeMenuStatus(){
    let groups = document.querySelectorAll('.znv-sidebar-title');
    groups.forEach(group => {
        group.addEventListener('click', (event) => {
            setMenuStatus(event.target, event.target.href);
        });

        /*
        if(sessionStorage["znv_sidebar_menu_" + group.href] != undefined){
            new bootstrap.Collapse(group, {
                hide: sessionStorage["znv_sidebar_menu_" + group.href]
            })
        }
        */
    });
}

function setMenuStatus(element, id){
    sessionStorage["znv_sidebar_menu_" + id] = element.classList.contains("collapsed");
}

function closeSideBar(){
    let sideBarCloseElement = document.getElementById("znv-side-bar-close");
  
    if (sideBarCloseElement){
        sideBarCloseElement.addEventListener('click', function() {

            let znvFooter = document.getElementById("znvFooter");
            if(znvFooter){
                znvFooter.classList.add("no-sidebar");
            }

            let element = document.getElementById("znvSidebar");
            element.classList.add("znv-sidebar-hidden");
            element.classList.remove("znv-sidebar-show");
        
            let main = document.getElementById("main");
            main.classList.add("main-nosidebar");
        
            let sideBurguer=document.getElementById("znv-sidebar-open");
            sideBurguer.classList.add("d-block");
        
            let navbarNavDropdown=document.getElementById("navbarNavDropdown");
            navbarNavDropdown.classList.remove("navbar-padding");
        });
    }
}

function openSideBar(){
    
    let sideBarOpenElement = document.getElementById("znv-sidebar-open");
    if(sideBarOpenElement){
        sideBarOpenElement.addEventListener('click', function() {

            let znvFooter = document.getElementById("znvFooter");
            if(znvFooter){
                znvFooter.classList.remove("no-sidebar");
            }
            
            let znvSidebar = document.getElementById("znvSidebar");
            znvSidebar.classList.remove("znv-sidebar-hidden");
            znvSidebar.classList.add("znv-sidebar-show");
        
            let main = document.getElementById("main");
            main.classList.remove("main-nosidebar");
        
            let sideBurguer=document.getElementById("znv-sidebar-open");
            sideBurguer.classList.remove("d-block");
        
            let navbarNavDropdown=document.getElementById("navbarNavDropdown");
            navbarNavDropdown.classList.add("navbar-padding");
        });
    }
}