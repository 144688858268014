document.addEventListener("DOMContentLoaded", function() {
    let secondaryPanelOne = document.getElementById("znv-js-panel-one");
    let secondaryPanelTwo = document.getElementById("znv-js-panel-two");

    if (secondaryPanelOne){
        secondaryPanelOne.addEventListener('click', function() {
            let element = document.getElementById("js-panel-secondary-1");
            element.classList.toggle("znv-sidebar-secondary-hidden");
        }
    )}else if (secondaryPanelTwo){
        secondaryPanelTwo.addEventListener('click', function() {
            let element = document.getElementById("js-panel-secondary-2");
            element.classList.toggle("znv-sidebar-secondary-hidden");
        }
    )}
})