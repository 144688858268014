class ZnvLoading {
  constructor(element, options = {}) {}

  show(){
    document.getElementsByClassName("znv-loading-backdrop")[0].classList.remove("znv-display-none");
  }

  hide (){
    document.getElementsByClassName("znv-loading-backdrop")[0].classList.add("znv-display-none");
  }
}

export default ZnvLoading;