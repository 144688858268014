class ZnvGeolocation {
    GRANTED = "granted";
    PROMPT = "prompt";
    DENIED = "denied";

    constructor(element, options = {}) {

    }
  
    handlePermission(success, state) {
        navigator.permissions.query({ name: "geolocation" }).then((result) => {
            if(result.state == state)
                success(result);
        });
    }

    isGranted(success) {
        this.handlePermission(success, this.GRANTED);
    }

    isPrompt(success) {
        this.handlePermission(success, this.PROMPT);
    }

    isDenied(success) {
        this.handlePermission(success, this.DENIED);
    }

    getCurrentPosition(success, error){
        navigator.geolocation.getCurrentPosition(
            success,
            error
        );
    }
  }
  
  export default ZnvGeolocation;