window.addEventListener('DOMContentLoaded', () => {
  let titleExportButton = document.querySelectorAll('#titleExportButton');
  if(titleExportButton.length > 0){
    titleExportButton[0].addEventListener('click', event => {
      let path = event.target.dataset.titleExportPath;
      exportData(path);
    });
  }
});

function exportData(path){
  let params = new window.URLSearchParams(window.location.search).toString();
  let url = path + "?" + params;

  //showLoading(loadingText);
  fetch(url)
      .then(response => response.json())
      .then(json => {
          let titleRow = exportStructure.rows.map(row => row.name);
          let ws_data = [titleRow]; 

          json.data.forEach(function(data){
              let record = [];
              exportStructure.rows.map(function(row){
                  let value = "";

                  switch(row.type){
                      case 'datetime':
                          if(data[row.id])
                              value = new Date(data[row.id]["date"])
                          break;
                      case 'entity':
                          let sequence = row.id.split(".");
                          if(data[sequence[0]])
                              value = data[sequence[0]][sequence[1]];
                          break;
                      case 'boolean':
                          value = (data[row.id]) ? "SI" : "";
                          break;
                      default:
                          value = data[row.id];
                  }
                  
                  record.push(value);
              });

              ws_data.push(record);
          });

          let wb = XLSX.utils.book_new();
          wb.Props = {
              "Title": exportStructure.document.name,
              "Subject": exportStructure.document.name,
              "CreatedDate": new Date()
          };
          wb.SheetNames.push("Export");
          
          let ws = XLSX.utils.aoa_to_sheet(ws_data, {raw: false});
          wb.Sheets["Export"] = ws;
          
          XLSX.writeFile(wb, exportStructure.document.fileName);
          //hideLoading();
      }).catch((e)=>{
          console.error(e);
          //hideLoading();
      });
}
