let PWA_MODAL_ID = 'pwa_install_modal';
let pwaModal;

window.addEventListener('DOMContentLoaded', () => {
  pwaModal = new bootstrap.Modal(document.getElementById(PWA_MODAL_ID)); 
  
  if ('serviceWorker' in navigator) {
    if(getPWADisplayMode() == "browser")
      requestInstall();
  }

  toggleInstallButton();
});


function toggleInstallButton(){
  let installButton = document.querySelector("#znv-install-pwa button");
  if(installButton){
    installButton.addEventListener('click', async (event) => {
      pwaModal.show();
    });

    if(!(JSON.parse(localStorage.getItem("znv-pwa-installed")))){
      installButton.classList.remove("znv-display-none");
    }else{
      installButton.classList.add("znv-display-none");
    }
  }
}

function getPWADisplayMode() {
  const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
  if (document.referrer.startsWith('android-app://')) {
    return 'twa';
  } else if (navigator.standalone || isStandalone) {
    return 'standalone';
  }
  return 'browser';
}

function requestInstall(){
  // PWA is not installed
  let deferredPrompt;
  window.addEventListener('beforeinstallprompt', (e) => {
      // Previene a la mini barra de información que aparezca en smartphones
      e.preventDefault();
      // Guarda el evento para que se dispare más tarde
      deferredPrompt = e;

      if(localStorage.getItem("znv-pwa-installed") === null){
        pwaModal.show();
      }else{
        localStorage.setItem("znv-pwa-installed", false);
      }
  });
  
  /*
  let closeButton = document.querySelector(`#${PWA_MODAL_ID} .btn-close`);
  closeButton.addEventListener('click', async (event) => {
    localStorage.setItem("znv-pwa-installed", false);
  });
  */

  let cancelButton = document.querySelector(`#${PWA_MODAL_ID} .btn-cancel`);
  cancelButton.addEventListener('click', async (event) => {
    localStorage.setItem("znv-pwa-installed", false);
  });

  let acceptButton = document.querySelector(`#${PWA_MODAL_ID} .btn-action`);
  acceptButton.addEventListener('click', async (event) => {
    pwaModal.hide();
    event.preventDefault();
    // Muestre el mensaje de instalación
    deferredPrompt.prompt();
    // Espera a que el usuario responda al mensaje
    const { outcome } = await deferredPrompt.userChoice;
    // De manera opcional, envía analíticos del resultado que eligió el usuario
    console.log(`User response to the install prompt: ${outcome}`);
    // Como ya usamos el mensaje, no lo podemos usar de nuevo, este es descartado
    deferredPrompt = null;
  });

  window.addEventListener('appinstalled', () => {
    // Limpiar el defferedPrompt para que pueda ser eliminado por el recolector de basura
    deferredPrompt = null;
    // De manera opcional, enviar el evento de analíticos para indicar una instalación exitosa
    console.log('PWA was installed');
    localStorage.setItem("znv-pwa-installed", true);
  });
}
