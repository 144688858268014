document.addEventListener("DOMContentLoaded", function() {
  const togglePassword = document.querySelector('.znv-password-eye');
  
  if(togglePassword){
    togglePassword.addEventListener("click", function () {
        let password = this.parentElement.querySelector('input[type="password"]');
        if(!password){
          password = this.parentElement.querySelector('input[type="text"]');
        }
        
        // toggle the type attribute
        const type = password.getAttribute("type") === "password" ? "text" : "password";
        password.setAttribute("type", type);
        
        // toggle the icon
        if(this.querySelector(".fa-eye").classList.contains("znv-display-none")){
          this.querySelector(".fa-eye").classList.remove("znv-display-none");
          this.querySelector(".fa-eye-slash").classList.add("znv-display-none");
        }else{
          this.querySelector(".fa-eye-slash").classList.remove("znv-display-none");
          this.querySelector(".fa-eye").classList.add("znv-display-none");
        }
    });
  }
})