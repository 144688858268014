document.addEventListener("DOMContentLoaded", function() {
  //avoid transitions when page loads
  let bodyTransitions=document.querySelector("body");
  if (bodyTransitions){
    bodyTransitions.classList.remove("preload");
  }

  //Bootstrap Tooltips
  let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  }) 
});