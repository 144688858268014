/************************ ADVERTENCIA ***********************************/
// Solo incluir en este archivo librearías que sean de uso global
/************************ ADVERTENCIA ***********************************/

import "./scss/main.scss"
import bootstrap from 'bootstrap/dist/js/bootstrap.js'
window.bootstrap = bootstrap

// Tom Select import
import TomSelect from 'tom-select/dist/js/tom-select.complete'
import 'tom-select/src/scss/tom-select.scss'
window.TomSelect = TomSelect

import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

library.add(fas, far, fab)
dom.i2svg()

/************************ ADVERTENCIA ***********************************/
// Solo incluir en este archivo librearías que sean de uso global
/************************ ADVERTENCIA ***********************************/

/************************/
// Custom 
/************************/

import "./js/common/znv-general";
import "./js/common/znv-primary-sidebar";
import "./js/common/znv-secondary-sidebar";
import "./js/common/znv-theme-toggle";
import "./js/common/znv-list";
import "./js/common/znv-list-paging";
import "./js/common/znv-list-search";
import "./js/common/znv-form-grouped-choices";
import "./js/common/znv-register-serviceworker";
import "./js/common/znv-password-field";
import "./js/common/znv-pwa";
import "./js/common/znv-export-xls";
import "./js/common/znv-template-xls";
import ZnvDropdown from "./js/common/znv-dropdown";
import ZnvGeolocation from "./js/common/znv-geolocation";
import ZnvLoading from "./js/common/znv-loading";
import XLSX from "xlsx";

global.ZnvLoading = ZnvLoading;
global.ZnvGeolocation = ZnvGeolocation;
global.ZnvDropdown = ZnvDropdown;
global.XLSX = XLSX;
