document.addEventListener("DOMContentLoaded", function() {
    if(document.querySelectorAll("[id$='_filter_search']").length > 0){
        // Submit search on ENTER
        let searchInputFilter = document.querySelectorAll("[id$='_filter_search']")[0];
        searchInputFilter.addEventListener('keyup', function(event) { 
            if (event.keyCode === 13) {
                submitFormFilter(event, this);
            }
        }, false);
        
        // Submit search on CLICK
        let searchIconFilter = document.getElementById('searchIconFilter');
        searchIconFilter.addEventListener('click', function(event) { 
            submitFormFilter(event, this);
        }, false);

        // Clear search on CLICK cross
        let cleanSearchFilter = document.getElementById('cleanSearchFilter');
        cleanSearchFilter.addEventListener('click', function(event) { 
            event.preventDefault();
            document.querySelectorAll("[id$='_filter_search']")[0].value = "";
            submitFormFilter(event, this, true);
        }, false);
    }
});

function submitFormFilter(event, element, clean){
    if(element.value !== ""){
        //Necessary to reset the current page befor submit
        document.querySelectorAll("[name='page']")[0].value = 1;
        event.preventDefault();
        document.querySelectorAll("form[name$='_filter']")[0].submit();
    }
}