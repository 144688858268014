document.addEventListener("DOMContentLoaded", function() {
    if(document.getElementById("znv_layout_list")){
        let orderBy = document.querySelectorAll("[id$='_filter_orderBy']")[0].value;
        let order = document.querySelectorAll("[id$='_filter_order']")[0].value;

        document.querySelectorAll('.sort-column-header .znv-pointer').forEach(element => {
            
            element.addEventListener('click', event => {
                order = (order == "asc") ? "desc" : (order == "desc") ? "" : "asc";
                
                document.querySelectorAll("[id$='_filter_order']")[0].value = order;
                document.querySelectorAll("[id$='_filter_orderBy']")[0].value = element.parentElement.dataset.key;

                document.querySelectorAll("form[name$='_filter']")[0].submit();
            })
        })
    }
});
