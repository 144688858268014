document.addEventListener("DOMContentLoaded", function() {
    let dropdownHeader=document.querySelectorAll('.dark-mode-dropdown');
    dropdownHeader.forEach((dropdownHeader)=>{
        dropdownHeader.addEventListener('click', function(e) {
        e.stopPropagation();
        });
    });

    // First localstorge seting
    if(localStorage.getItem('znv-darkmode') === null){
        localStorage.setItem('znv-darkmode','f');
    }else if (localStorage.getItem('znv-darkmode') === 't') {
        document.documentElement.classList.toggle("dark-theme", true);
        document.getElementById('themeToggle').checked = true;
    }

    // Darkmode Toggle
    var themeBtn = document.getElementById("themeToggle");
    if(themeBtn){
        themeBtn.addEventListener('click', function(){
            // toggle variable on local storage
            localStorage.setItem('znv-darkmode', localStorage.getItem('znv-darkmode') === 'f' ? 't' : 'f');
            // toggle the dark theme
            document.documentElement.classList.toggle("dark-theme");
        });
    }
})