document.addEventListener("DOMContentLoaded", function() {
    if(document.getElementById("znv_layout_list")){
        var paginateButtons = document.querySelectorAll('#znv_layout_list .js-paginate-btn');
        if(paginateButtons.length > 0){
            paginateButtons.forEach(paginateButton => {
                paginateButton.addEventListener('click', (event) => {
                    changePage(event.target.dataset.page);
                });
            });
        }

        var selectNumberOfElements = document.querySelectorAll('#znv_layout_list #elementsPerPage');
        if(selectNumberOfElements.length > 0){
            selectNumberOfElements[0].addEventListener('change', (event) => {
                changeNumberOfElementsPerPage(event.target.value);
            });
        }
    }
});

function changeNumberOfElementsPerPage(numElements)
{
    document.querySelectorAll("[name='page']")[0].value = 1;
    document.querySelectorAll("[name='pageRange']")[0].value = numElements;
    document.querySelectorAll("form[name$='_filter']")[0].submit();
}

function changePage(page)
{
    document.querySelectorAll("[name='page']")[0].value = page;
    document.querySelectorAll("form[name$='_filter']")[0].submit();
}