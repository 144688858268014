window.addEventListener('DOMContentLoaded', () => {
    let importTemplateButton = document.getElementById("importTemplateButton");
    if(importTemplateButton)
        importTemplateButton.addEventListener("click", downloadImportTemplate, false);
});

function downloadImportTemplate(){
    let wb = XLSX.utils.book_new();
    wb.Props = {
        "Title": importTemplateStructure.document.name,
        "Subject": importTemplateStructure.document.name,
        "CreatedDate": new Date()
    };
    wb.SheetNames.push("Template");
    let titleRow = importTemplateStructure.rows.map(row => row.name);
    let ws_data = [titleRow]; 
    let ws = XLSX.utils.aoa_to_sheet(ws_data);
    wb.Sheets["Template"] = ws;

    XLSX.writeFile(wb, importTemplateStructure.document.fileName, { bookType: "csv", FS:";" });
}
